import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/_custom.scss'
import './scss/style.scss'
import '../src/css/style.css'
import { SpinnerBigX } from './components/Spinner'
import { AlertProvider } from './contexts/AlertContext'
import Alert from './components/Alert'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const FirstLogin = React.lazy(() => import('./views/firstLogin/FirstLogin'))
const RedefinePassword = React.lazy(() => import('./views/redefinePassword/redefinePassword'))
const ApproveDocument = React.lazy(() =>
  import('./views/documentManagement/ApproveDocument/ApproveDocument'),
)
const VisibleNonCondormity = React.lazy(() =>
  import('./views/nonconformity/views/VisibleNonConformities/visibleNonConformities'),
)

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<SpinnerBigX />}>
        <QueryClientProvider client={queryClient}>
          <AlertProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <Alert />
            <Routes>
              <Route path="/login" name="login" element={<Login />} />
              <Route path="/firstLogin" name="FirstLogin" element={<FirstLogin />} />
              <Route
                path="/redefinepassword"
                name="Redefine Password"
                element={<RedefinePassword />}
              />
              <Route path="*" name="Home" element={<DefaultLayout />} />
              <Route path="/approvedocument" name="ApproveDocument" element={<ApproveDocument />} />
              <Route
                path="/visibleNonCondormity/:id"
                name="VisibleNonCondormity"
                element={<VisibleNonCondormity />}
              />
            </Routes>
          </AlertProvider>
        </QueryClientProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
