import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userData: {}
}


const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload ;
        },

        removeUserData: (state, action) => {
            state.userData = initialState
        }
    }
});


export const { setUserData, removeUserData } = userDataSlice.actions

export default userDataSlice.reducer