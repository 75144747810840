import { createContext, useContext, useEffect, useRef, useState } from 'react'

export const AlertContext = createContext({
  alertData: {
    message: '',
    type: '',
  },
  hideAlert: () => {},
  showAlert: () => {},
})


export const useAlert = () => useContext(AlertContext)


export const AlertProvider = ({ children }) => {

  const alertDefaultValue = {
    message: '',
    type: '',
  }

  const [alertData, setAlertData] = useState(alertDefaultValue)

  const showAlert = (message, type) => {
    setAlertData({message, type})
  }

  const hideAlert = () => {
    setAlertData(null)
  }


  const timeoutRef = useRef()

  useEffect(() => {
    timeoutRef.current = setTimeout(hideAlert, 10000)
    return () => clearTimeout(timeoutRef.current)
  }, [alertData])

 

  return (
    <AlertContext.Provider value={{ alertData, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  )
}

