const { CAlert } = require('@coreui/react-pro')
import IconSuccess from 'src/assets/icons/sucesso.png'
import IconDanger from 'src/assets/icons/botao-x.png'
import Iconwarning from 'src/assets/icons/aviso.png'
import { useAlert } from 'src/contexts/AlertContext'
import React from 'react'

const Alert = () => {
  const { alertData, hideAlert } = useAlert()

  if (!alertData || !alertData.message?.trim()) {
    return null
  }

  return (
    <div className="align-message">
      <div className="position">
        <CAlert
          color={
            alertData.type === 'success'
              ? 'success'
              : alertData.type === 'error'
              ? 'danger'
              : 'warning'
          }
          className={
            alertData.type === 'success'
              ? 'message-success'
              : alertData.type === 'error'
              ? 'message-danger'
              : 'message-warning'
          }
          dismissible
          onClose={hideAlert}
        >
          <img
            src={
              alertData.type === 'success'
                ? IconSuccess
                : alertData.type === 'error'
                ? IconDanger
                : Iconwarning
            }
            alt={alertData.message}
            width={30}
          />
          {alertData.message}
        </CAlert>
      </div>
    </div>
  )
}

export default React.memo(Alert)
