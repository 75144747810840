import React from 'react'

export function SpinnerBigX() {
  return (
    <div className="spinner-overlay-big-x">
      <div className="spinner">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  )
}

export function SpinnerBig() {
  return (
    <div className="spinner-overlay-big">
      <div className="spinner">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  )
}

export function SpinnerSmallX() {
  return (
    <div className="spinner-overlay-small-x">
      <div className="spinner">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  )
}

export function SpinnerSmall() {
  return (
    <div className="spinner-overlay-small">
      <div className="spinner">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  )
}
